import React from "react"
import styled from 'styled-components';
import BCNY from '../images/BCNY.svg';

import SEO from "../components/seo"
import { BlueRoot, TextMargins } from "../components/Elements"
import DefaultPageLayout from "../components/DefaultPageLayout";

const FlexSpace = styled.div`
  flex-grow: 1;
`;

const Title = styled.h1`
  text-align: left;
  font-weight: normal;
`;

const LogoImage = styled.img`
  height: 3rem;
`;

const Margins = styled.div`
  padding: 2rem;
  width: 100%;
  box-sizing: border-box;
`;

const NotFoundPage = () => (
  <DefaultPageLayout>
    <BlueRoot>
      <SEO title="Not Found | The Browser Company" />
      <Margins>
        <Title>Error 404</Title>
      </Margins>
      <FlexSpace />
      <Margins style={{textAlign: 'right'}}>
        <LogoImage src={BCNY} />
      </Margins>
    </BlueRoot>
  </DefaultPageLayout>
)

export default NotFoundPage
