import React from "react"
import styled from 'styled-components';
import { DarkBlue, InternetBlue } from "./Colors";

export const BlueSection = styled.div`
    background-color: ${props => props.bgColor};
    border: 1px solid ${props => props.bgColor};
    box-sizing: border-box;
    color: white;
`;

export const TextMargins = styled.div`
    align-self: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    width: 100%;
    max-width: 800px;
`;

const BlueChipA = styled.a`
    width: 100px;
    height: 100px;
    margin-top: 1em;
    margin-bottom :1em;
    background-color: ${InternetBlue};
    display: block;
    position: relative;
    cursor: help;
    &:hover:after {
        content: " ";
        position: absolute;
        top: -100vh;
        left: -100vw;
        width: 200vw;
        height: 200vh;
        background-color: ${InternetBlue};
        pointer-events: none;
        z-index: -100;
    }
`;
export const BlueChip = () => <BlueChipA href='#' />;

// aspect: width/height
export const AspectRatioImage = styled.div<{ aspect: number, src: string }>`
    width: 100%;
    background-image: url(${props => props.src});
    background-size: contain;
    background-repeat: no-repeat;
    padding-bottom: ${props => (100 / props.aspect)}%;
`;

export const Root = styled.div`
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
`;

export const CleanTextRoot = styled(Root)`
    font-size: 18px;
    line-height: 1.8;
    color: #131215;
`;

export const BlueRoot = styled(Root)`
    background-color: ${InternetBlue};
    color: white;
    box-sizing: border-box;
    border: 1rem solid white;
`;

