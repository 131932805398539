/**
 * Default Layout Component. Used
 * by all pages aside from Jobs
 */
import React from "react"
import { createGlobalStyle } from "styled-components";


const BodyStyle = createGlobalStyle`
  html, body {
    margin: 0;
    font-family: 'Nunito', 'Helvetica Neue', -apple-system, BlinkMacSystemFont, sans-serif;
  }

  html.dark body {
    background-color: #131215;
  }

  @media screen and (min-width: 600px) {
    html {
      font-size: 20px;
    }
  }

  @media screen and (min-width: 800px) {
    html {
      font-size: 22px;
    }
  }

  @media screen and (min-width: 1000px) {
    html {
      font-size: 24px;
    }
  }

  @media screen and (min-width: 1200px) {
    html {
      font-size: 26px;
    }
  }

  html, body, #gatsby-focus-wrapper, #___gatsby {
    height: 100%;
  }

  .IndexPage {
    background-color: #0047FF;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 16px;
  }

  header,footer {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 60px;
  }

  #logo {
    margin: 2em;
    width: 80%;
    align-self: center;
    flex-grow: 1;
    flex-shrink: 1;
    background-image: url(../images/tbcny.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  @media screen and (min-width: 1024px) {
    #logo {
      width: 50%;
      max-height: 50%;
    }
  }

  header > a:link, header > a:visited {
    color: white;
    margin: 10px;
    margin-right: 20px;
  }
  header > a:hover {
    opacity: 0.66;
  }
  header > a:active {
    opacity: 0.5;
  }
  footer .EmailField {
    display: none;
  }
  @media screen and (max-width: 500px) {
    header .EmailField {
        display: none;
    }
    footer .EmailField {
        display: block;
    }
    .EmailField form {
        font-size: 16px;
    }
  }
`

const DefaultPageLayout = ({ children }) => {
  return (
    <>
      <BodyStyle />
      {children}
    </>
  )
}

export default DefaultPageLayout
